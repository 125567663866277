<template>
	<div class="spares-details">

		<el-breadcrumb separator="/">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>详细参数</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="spares-details-title">
			<h1>{{ ruleForm.name }}</h1>
			<!-- <div class="time" v-if="ruleForm.updateTime">
					最后修改时间：<span>{{ ruleForm.updateTime }}</span>
				</div>
				<div class="tips">
					填写规范：<span>
						为提供更详尽的产品数据查询服务，请认真填写各项产品信息。
						供应商为供应商名称首字母缩写； 成套产品以主芯零件号为准（如空滤）；请尽可能输入完整的产品图片、尺寸、规格等详细信息。
					</span>
				</div> -->
		</div>
		<div class="content">
			<div class="left">
				<div class="content-row">
					<div class="info">
						<ul>
							<li><span>备品名称</span><em>{{ruleForm.name}}</em></li>
							<li><span>订货代码</span><em>{{ruleForm.buyCode}}</em></li>
							<li><span>可用数量</span><em>{{ruleForm.qty}}</em></li>
							<li><span>供应商代码</span><em>{{ruleForm.supplier}}</em></li>
							<li><span>容量单位</span><em>{{ruleForm.unit}}</em></li>
							<li><span>包装定额</span><em>{{ruleForm.packageQuota}}</em></li>
						</ul>
					</div>
					<div class="stock">
						<div class="content-title">库存数</div>
						<ul>
							<li v-for="item in ruleForm.stores" :key="item.id">
								<span>{{item.storeName.replace('区域库-', '').replace('区域库分库-', '')}}</span><em>{{item.qty}}</em>
							</li>
						</ul>
					</div>
				</div>
				<div class="content-row">
					<div class="remark">
						<div class="content-title">适配车型</div>
						<div class="remark-com">
							<p v-for="item in ruleForm.adapters" :key="item.id">
								{{item}}
							</p>
							<p>
								<el-input type="textarea" :rows="5" placeholder="请输入适配车型,多个车型输入要用分隔符"
									v-model="ruleForm.adapter"></el-input>
							</p>
						</div>
					</div>
					<div class="remark">
						<div class="content-title">规格备注</div>
						<div class="remark-com">
							<p><span>包装定额：</span><input size="mini" style="width: 49px" v-model="ruleForm.newPackageQuota" /></p>
							<p><span>材质：</span>
								<select v-model="ruleForm.materialId" placeholder="请选择">
									<option v-for="item in arrayMaterial" :key="item.id" :label="item.name" :value="item.id">
									</option>
								</select>
							<p><span>净重量：</span><input size="mini" style="width: 49px" v-model="ruleForm.weight" /><em>kg</em>
							</p>
							<p><span>木箱带包装重量：</span><input size="mini" style="width: 49px" v-model="ruleForm.outWeight" /><em>kg</em>
							</p>
						</div>
					</div>
					<div class="remark">
						<div class="content-title">产品详细尺寸</div>
						<div class="remark-com">
							<p><span>外包装：</span>
								<input size="mini" style="width: 29px" v-model="ruleForm.outPkgLen" /><em>cm *</em>
								<input size="mini" style="width: 29px" v-model="ruleForm.outPkgWidth" /><em>cm *</em>
								<input size="mini" style="width: 29px" v-model="ruleForm.outPkgHeight" /><em>cm</em>
							</p>
							<p><span>裸件：</span><input size="mini" style="width: 29px" v-model="ruleForm.innerLen" /><em>cm *</em>
								<input size="mini" style="width: 29px" v-model="ruleForm.innerWidth" /><em>cm *</em>
								<input size="mini" style="width: 29px" v-model="ruleForm.innerHeight" /><em>cm</em>
							</p>
							<p><span>其他尺寸：</span><input size="mini" style="flex: 1;" v-model="ruleForm.otherSize" /></p>
						</div>
					</div>
				</div>
				<el-form :model="modify" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm-style">
					<el-form-item label="是否通过" prop="status">
						<el-radio-group :disabled="origin_status != 2" v-model="modify.status" @change="chooseChange">
							<el-radio border :label="-10">不通过</el-radio>
							<el-radio border :label="10">通过</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="不通过原因" prop="rejectReason" v-if="modify.status == -10">
						<el-input :disabled="origin_status != 2" type="textarea" v-model="modify.rejectReason" rows="4">
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-button v-if="origin_status == 2" class="main-btn" type="primary"
							@click="submitForm('ruleForm')">提交审核</el-button>
						<el-button v-if="origin_status == 10" class="main-btn" type="primary"
							@click="submitForm('ruleForm')">修改保存</el-button>
						<!--  -->
					</el-form-item>
				</el-form>
			</div>
			<div class="spares-carousel-wrapper">
				<div class="datu">
					<el-image v-if="datuImgUrl" class="datu-img" :src="datuImgUrl" :preview-src-list="srcList">
					</el-image>
				</div>

				<!-- <el-carousel class="spares-carousel" interval="60000000" indicator-position="none" @change="carouselChange"
					ref="carousel">
					<el-carousel-item v-for="(item,index) in ruleForm.imgs" :key="item.id" :name="index">
						<img :src="item.img" />
					</el-carousel-item>
				</el-carousel> -->
				<div class="xiaotu">
					<div class="xiaotu-img" :class="xiaotuIndex==index?'active':''" v-for="(item,index) in ruleForm.imgs"
						:key="item.id" @click="xiaotuImg(index)">
						<img :src="item.img" />
						<el-checkbox v-model="item.major">设为主图</el-checkbox>
						<i class="el-icon-delete" @click.stop="uploadRemove(index)"></i>
					</div>
					<div class="upload-img">
						<el-upload list-type="picture-card" :show-file-list="false" action="/api/admin/upload" :headers="headers"
							:on-remove="uploadRemove" :on-success="uploadImg">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>

				</div>

			</div><!--  -->
			<!-- <me-carousel class="spares-carousel" interval="6000">
				<me-carousel-item v-for="item in 4" :key="item">
					<h3 class="small">{{ item }}</h3>
				</me-carousel-item>
			</me-carousel> -->

		</div>

	</div>
</template>

<script>
	import http from "../http.js";
	import power from "../power.js";
	import commFunc from "../commFunc.js";
	// import meCarousel from "../../components/carousel.vue"
	// import meCarouselItem from "../../components/carousel-item.vue"
	export default {
		components: {
			// meCarousel,
			// meCarouselItem
		},
		data() {
			return {
				srcList: [],
				datuImgUrl: '',
				origin_status: '',
				reasonShow: true,
				xiaotuIndex: '0',
				slide: [1, 2, 3, 4, 5], //定义一个小数组用来区分
				headers: {
					token: localStorage.getItem("token"),
				},
				fileList: [],
				dialogImageUrl: "",
				dialogVisible: false,
				disabled: false,
				ruleForm: {
					id: "",
					choose: '1',
					reason: '',
					productId: "",
					name: "",
					model: "",
					ywykNo: "",
					marketNo: "",
					series: "",
					suitableModel: "",
					spec: "",
					supplier: "",
					production: "",
					workingMileage: "",
					priceCost: "",
					rateInner: "",
					priceInner: "",
					rateStore: "",
					priceStore: "",
					rateStoreTax: "",
					priceStoreTax: "",
					rateFleet: "",
					priceFleet: "",
					rateFleetTax: "",
					priceFleetTax: "",
					rateDiscountFleet: "",
					priceFleetTaxDiscount: "",
					rateEndUser: "",
					priceEndUser: "",
					rateEndUserTax: "",
					priceEndUserTax: "",
					ratePartner: "",
					pricePartner: "",
					ratePartnerTax: "",
					pricePartnerTax: "",
					rateAutoCity: "",
					priceAutoCity: "",
					rateAutoCityTax: "",
					priceAutoCityTax: "",
					imgs: [],
				},
				originObjStr: "",
				rules: {
					status: [{
						required: true,
						message: '请选择是否通过',
						trigger: 'change'
					}],
					rejectReason: [{
						required: true,
						message: '请填写不通过原因',
						trigger: 'blur'
					}]
				},
				categoryList: [],
				modify: {
					id: ''
				},
				arrayMaterial: [{
					id: 0,
					name: '请选择材质',
				}, {
					id: 1,
					name: '金属'
				}, {
					id: 2,
					name: '塑料'
				}, {
					id: 3,
					name: '橡胶'
				}, {
					id: 4,
					name: '玻璃'
				}, {
					id: 5,
					name: '纺织品'
				}, {
					id: 6,
					name: '润滑油液'
				}, {
					id: 7,
					name: '石棉纸板制品'
				}, {
					id: 100,
					name: '其他'
				}, ],
			};
		},
		created() {
			if (this.$route.query.id) {
				this.modify.id = this.$route.query.id;
				this.getModify();
			}
		},
		mounted() {
			this.datuImgUrl = this.ruleForm.imgs[0].img
		},

		methods: {
			uploadRemove(index) {
				this.$confirm('确定要删除该图片吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {

					this.ruleForm.imgs.splice(index, 1)
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
				}).catch(() => {});
			},
			uploadImg(res, file) {
				console.log(res, file)
				this.ruleForm.imgs.push({
					type: 'upload',
					img: res.data.url
				})
			},
			getMaterialName(id) {
				let arr = ['', '金属', '塑料', '橡胶', '玻璃', '纺织品', '润滑油液', '石棉纸板制品', '其他']
				if (id == 100) {
					id = arr.length - 1
				}
				return arr[id]
			},
			getModify() {
				http.fetchGet("/api/admin/product/modify", {
					id: this.modify.id,
				}).then((res) => {
					if (res.data.code == 0) {
						this.modify = res.data.data;
						this.origin_status = this.modify.status
						this.getProduct();
					}
				});
			},
			chooseChange(val) {
				console.log(val)
			},
			carouselChange(index) {
				this.xiaotuIndex = index
			},
			xiaotuImg(index) {
				this.xiaotuIndex = index
				this.datuImgUrl = this.ruleForm.imgs[index].img
				// this.$refs.carousel.setActiveItem(index)
			},
			power(p) {
				return power(p);
			},
			// handleRemove(file) {
			//     console.log(file);
			// },
			// handlePictureCardPreview(file) {
			//     this.dialogImageUrl = file.url;
			//     this.dialogVisible = true;
			// },
			rateDiv100(item) {
				commFunc.rateDiv100(item)
			},
			rateMul100(item) {
				commFunc.rateMul100(item)
			},
			getCategory() {
				http.fetchGet('/api/admin/category/list').then(res => {
					if (res.data.code == 0) {
						this.categoryList = res.data.data
					}
				})
			},
			getProduct() {
				let modifyDbArr = ['new_package_quota', 'material_id', 'weight', 'out_weight', 'out_pkg_len',
					'out_pkg_width',
					'out_pkg_height', 'inner_len', 'inner_width', 'inner_height', 'other_size', 'imgs'
				]
				let modifyArr = ['newPackageQuota', 'materialId', 'weight', 'outWeight', 'outPkgLen', 'outPkgWidth',
					'outPkgHeight', 'innerLen', 'innerWidth', 'innerHeight', 'otherSize', 'imgs'
				]
				http.fetchGet("/api/admin/product", {
					id: this.modify.productId,
				}).then((res) => {
					if (res.data.code == 0) {
						this.ruleForm = res.data.data;
						this.originObjStr = JSON.stringify(this.ruleForm)
						this.modify.details.forEach(item => {
							let idx = modifyDbArr.indexOf(item.colName);
							if (idx >= 0) {
								this.ruleForm[modifyArr[idx]] = item.afterValue
							}
							if (item.colName == 'imgs') {
								let imgs = JSON.parse(item.afterValue)
								this.ruleForm.imgs = []
								imgs.forEach(item => {
									this.ruleForm.imgs.push(item)
								})
								console.log(this.ruleForm.imgs)
								if (this.ruleForm.imgs.length > 0) {
									this.datuImgUrl = this.ruleForm.imgs[0].img
								}
								this.ruleForm.imgs.forEach((item) => {
									this.srcList.push(item.img)
								})
							}
						})
					}
				});
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let tip = "确定要审核吗?"
						if (this.origin_status == 10) {
							tip = "确定要保存吗?"
						}
						this.$confirm(tip, "提示", {
								confirmButtonText: "确定",
								cancelButtonText: "取消",
								type: "warning",
							}).then(() => {
								let modifyDbArr = ['new_package_quota', 'material_id', 'weight', 'out_weight',
									'out_pkg_len', 'out_pkg_width',
									'out_pkg_height', 'inner_len', 'inner_width', 'inner_height',
									'other_size', 'imgs', 'adapter'
								]
								let modifyArr = ['newPackageQuota', 'materialId', 'weight', 'outWeight',
									'outPkgLen', 'outPkgWidth',
									'outPkgHeight', 'innerLen', 'innerWidth', 'innerHeight', 'otherSize',
									'imgs', 'adapter'
								]
								let origin = JSON.parse(this.originObjStr)
								this.modify.details = []
								modifyArr.forEach((m, idx) => {
									if (m != 'imgs') {
										if (origin[m] != this.ruleForm[m]) {
											this.modify.details.push({
												colName: modifyDbArr[idx],
												beforeValue: origin[m],
												afterValue: this.ruleForm[m]
											})
										}
									} else {
										let originImgs = JSON.stringify(origin.imgs)
										let imgs = JSON.stringify(this.ruleForm.imgs)
										if (originImgs != imgs) {
											this.modify.details.push({
												colName: modifyDbArr[idx],
												beforeValue: originImgs,
												afterValue: imgs
											})
										}
									}
								})

								let savedTip = "审核成功!"
								if (this.origin_status == 10) {
									savedTip = "保存成功!"
								}
								http.fetchPost('/api/admin/product/verify/modify', this.modify).then(
									response => {
										if (response.data.code == 0) {
											http.fetchPost(
												"/api/admin/product/modify/verify",
												this.modify
											).then((res) => {
												if (res.data.code == 0) {
													this.$message({
														type: "success",
														message: savedTip,
													});
													setInterval(() => {
														window.close()
													}, 2000)
													// this.$router.push("/examine");
												} else {
													this.$message.error(res.data.desc);
												}
											});
										} else {
											this.$message.error(response.data.desc)
										}
									})

							})
							.catch(() => {});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			saveProduct() {
				this.rateDiv100(this.ruleForm);
				//保存
				http.fetchPost("/api/admin/product", this.ruleForm).then((res) => {
					if (res.data.code == 0) {
						this.$message.success("保存成功");
						this.$router.push("/");
					} else {
						this.$message.error(res.data.desc);
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			priceChange() {
				this.$nextTick(() => {
					commFunc.priceCalc(this.ruleForm)
					this.$forceUpdate();
				});
			},
			beforeAvatarUpload(file) {
				const isJPG =
					file.type === "image/jpeg" || file.type === "image/png";
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error("上传图片只能是 PNG 或 JPG 格式!");
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2MB!");
				}
				return isJPG && isLt2M;
			},
			handlePictureCardSuccess(res, file, fileList) {
				this.updateImages(fileList);
			},
			handleRemove(file, fileList) {
				this.updateImages(fileList);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			updateImages(fileList) {
				this.ruleForm.imgs = [];
				console.log(fileList);
				for (var i = 0; i < fileList.length; i++) {
					if (fileList[i].response) {
						this.ruleForm.imgs = this.ruleForm.imgs.concat([{
							img: fileList[i].response.data.url,
						}, ]);
					} else {
						this.ruleForm.imgs = this.ruleForm.imgs.concat([{
							img: fileList[i].url,
						}, ]);
					}
					console.log(this.ruleForm.imgs);
				}
			},
		},

	};
</script>

<style lang="scss">
	@import '~@/assets/css/spares-details.scss';
</style>
